import { createContext } from "react";
// @ts-expect-error - bundler mode is not available with current TS version - have to be upgraded
import Keycloak from "keycloak-js";

export type KeycloakContextType = {
  keycloak: Keycloak;
  initialized: boolean;
  isAuthenticated: boolean;
};

export const KeycloakContext = createContext<KeycloakContextType | null>(null);
